<template>
  <v-dialog v-model="show" max-width="700px" scrollable>
    <v-card>
      <v-toolbar flat dense color="grey lighten-2">
        <v-toolbar-title>
          Visualizar tarefa
          <span v-if="task && !isCustomer" class="body-2">
            <i>#{{ task.id }}</i>
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn icon small @click="show = false">
            <v-icon v-text="'mdi-close'" />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text v-if="task.id" class="pt-4 text-center">
        <v-row class="mb-2" v-if="!isCustomer">
          <v-col class="text-left" align-self="center">
            <v-btn
              color="success"
              :loading="loadingCompleted"
              @click="completed"
              :disabled="!!task.completedAt"
              depressed
              small
            >
              <v-icon v-text="'mdi-check'" left />
              {{ task.completedAt ? 'Finalizada' : 'Finalizar' }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="!isCustomer">
          <v-col>
            <v-text-field
              :value="task.customer.companyName"
              label="Cliente"
              readonly
              hide-details="auto"
              dense
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="task.title"
              hide-details="auto"
              label="Título"
              readonly
              dense
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="task.description"
              hide-details="auto"
              label="Descrição"
              readonly
              dense
              outlined
              rows="3"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              :value="deliveryDeadlineDate"
              label="Data de vencimento"
              hide-details="auto"
              readonly
              dense
              outlined
            />
          </v-col>
          <v-col>
            <v-text-field
              :value="alertDate"
              label="Data de alerta"
              hide-details="auto"
              readonly
              dense
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              :value="createdAt"
              label="Criada em"
              hide-details="auto"
              readonly
              dense
              outlined
            />
          </v-col>
          <v-col>
            <v-text-field
              :value="task.createdByUser.name"
              label="Criada por"
              hide-details="auto"
              readonly
              dense
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              :value="completedAt"
              label="Finalizada em"
              hide-details="auto"
              readonly
              dense
              outlined
            />
          </v-col>
          <v-col>
            <v-text-field
              :value="task.completedByUser ? task.completedByUser.name : ''"
              label="Finalizada por"
              hide-details="auto"
              readonly
              dense
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import customerTasksApi from '@/api/customer-tasks'
import dayjs from 'dayjs'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    taskId: {
      type: Number,
      default: 0,
    },
    isCustomer: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      loadingCompleted: false,
      task: {},
    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },

    deliveryDeadlineDate() {
      return this.task?.deliveryDeadlineDate
        ? dayjs(this.task.deliveryDeadlineDate).format('DD/MM/YYYY')
        : ''
    },

    alertDate() {
      return this.task?.alertDate
        ? dayjs(this.task.alertDate).format('DD/MM/YYYY')
        : ''
    },

    completedAt() {
      return this.task?.completedAt
        ? dayjs(this.task.completedAt).format('DD/MM/YYYY HH:mm')
        : ''
    },

    createdAt() {
      return this.task ? dayjs(this.task.createdAt).format('DD/MM/YYYY HH:mm') : ''
    },
  },

  watch: {
    show(val) {
      if (!val) return

      this.load()
    },
  },

  methods: {
    async load() {
      try {
        this.task = {}
        this.loading = true
        const response = await customerTasksApi.get(this.taskId, this.isCustomer)
        this.task = response.data.task
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loading = false
      }
    },

    async completed() {
      try {
        this.loadingCompleted = true
        await customerTasksApi.completed(this.taskId)
        this.load()
        this.$emit('completed')
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loadingCompleted = false
      }
    }
  },
}
</script>
